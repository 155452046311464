import SortCentralWrapper from "@jordibosch20/software_tools_package/dist/pages/SortCentralWrapper.component.js"
import styles from "./App.module.css";
import { getSortedLines } from "@jordibosch20/software_tools_package/dist/utils/sort.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <SortCentralWrapper title={"Sort Lines"} f={getSortedLines}></SortCentralWrapper>
      </div>
      <div className={styles.text}>
        <p><strong>Introduction</strong></p>
        <p>In the digital era, where data is omnipresent, managing textual and numerical information efficiently is crucial for professionals across various fields. Whether you're an editor, writer, data analyst, or researcher, the clarity and organization of your data can significantly impact your work's outcome. The Advanced Sorting Tool is designed to address these challenges, offering a comprehensive solution for sorting and deduplicating words, sentences, and numbers. Let's explore how this tool can streamline your workflow and enhance productivity.</p>
        <p><strong>Why Sorting and Deduplication Matter</strong></p>
        <p>Sorting and removing duplicates are more than just organizational tasks; they are essential steps in ensuring the accuracy and relevance of your data. Whether you're preparing a report, analyzing datasets, or writing content, these processes help in:</p>
        <ul>
        <li>Enhancing readability and comprehension.</li>
        <li>Improving data analysis and decision-making.</li>
        <li>Reducing clutter and focusing on relevant information.</li>
        <li>Saving time and resources in manual data cleaning.</li>
        </ul>
        <p><strong>Features of the Advanced Sorting Tool</strong></p>
        <ul>
        <li><strong>Versatile Sorting</strong>: Easily sort words, sentences, and numbers based on various criteria, such as alphabetical order, length, or numerical value.</li>
        <li><strong>Efficient Deduplication</strong>: Automatically identify and remove duplicate entries with a single click, ensuring your data is concise and relevant.</li>
        <li><strong>User-Friendly Interface</strong>: Designed with simplicity in mind, making it accessible to users of all technical skill levels.</li>
        <li><strong>High-Speed Processing</strong>: Handle large volumes of data quickly, saving you valuable time in your data organization tasks.</li>
        <li><strong>Data Privacy</strong>: Process your data securely, ensuring that your information remains confidential and is not stored or used improperly.</li>
        </ul>
        <p><strong>How to Use the Advanced Sorting Tool</strong></p>
        <ol>
        <li><strong>Input Your Data</strong>: Copy and paste your text or numbers into the designated area.</li>
        <li><strong>Choose Your Sorting Criteria</strong>: Select how you'd like to sort your data (e.g., alphabetically, by length, numerically).</li>
        <li><strong>Select Deduplication</strong>: Opt-in to remove duplicates if needed.</li>
        <li><strong>Process</strong>: Click the sort and deduplicate button.</li>
        <li><strong>Review and Use Your Organized Data</strong>: Your sorted and deduplicated data is now ready for use.</li>
        </ol>
        <p><strong>Applications Across Industries</strong></p>
        <ul>
        <li><strong>Academic Research</strong>: Organize research notes, references, and data sets efficiently.</li>
        <li><strong>Content Creation</strong>: Manage articles, blogs, and scripts by sorting content and removing repetitive information.</li>
        <li><strong>Data Analysis</strong>: Cleanse datasets by sorting variables and removing duplicate entries for accurate analysis.</li>
        <li><strong>Business Reporting</strong>: Compile reports with organized data, ensuring clarity and precision in communication.</li>
        </ul>
        <p></p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;